import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../../components/seo'
import Layout from '../../components/layout'
import Img from 'gatsby-image'

import styles from '../../components/thankyou.module.css'



class RootIndex extends React.Component {
  render() {
    const page = get(this, 'props.data.page')

    return (
      <Layout location={this.props.location} wideNav noTop>
        <SEO noIndex={true}></SEO>
        <div className={"content " + styles.flex}>
          {page.icon != null && <Img 
            className={styles.yetiImage}
            durationFadeIn={0}
            fluid={{...page.icon.fluid}}
            loading="eager"
          />}
          {page.body != null && <div className={styles.body} dangerouslySetInnerHTML={{
                __html: page.body.childContentfulRichText.html,
          }}></div>}
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ThanksQuery {
    site {
      siteMetadata {
        title
      }
    }

    page: contentfulPage(name: {eq: "Thank you"}) {
      title
      subtitle
      body {
        childContentfulRichText {
          html
        }
      }
      icon {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
